.root {
    width: 1024px ;
}


.trContainer {
    display: grid;
    grid-template-columns: 425px 2fr 1.5fr 1fr 1fr 1fr;
    margin-bottom: 15px;
    box-shadow: 0 4px 60px var(--drop-down-extra-shadow);
    border-radius: 20px;
    align-items: center;
    font-size: var(--fs-large);
    text-align: center;
}


.info {
    text-align: left;
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
    .root {
        width: 950px ;
    }
}

@media screen and (min-width: 900px) and (max-width: 1024px) {
    .root {
        width: 830px ;
    }
}

@media screen and (min-width:768px) and (max-width: 900px) {
    .root {
        width: 700px ;
    }

    .trContainer {
        grid-template-columns: 325px 2fr 1.5fr 1fr 1fr 1fr;
    }
}

@media screen and (min-width:650px) and (max-width: 768px) {
    .root {
        width: 580px ;
    }

    .trContainer {
        grid-template-columns: 225px 2fr 1.5fr 1fr 1fr 1fr;
        font-size: var(--fs-small);
    }
}

@media screen and (min-width:550px) and (max-width: 650px) {
    .root {
        width: 490px ;
    }

    .trContainer {
        grid-template-columns: 175px 2fr 1.5fr 1fr 1fr 1fr;
        font-size: var(--fs-small);
    }
}

@media screen and (min-width:425px) and (max-width: 550px) {
    .root {
        width: 390px ;
    }

    .trContainer {
        grid-template-columns:125px 2fr 1.5fr 1fr 1fr 1fr;
        font-size: var(--fs-microscopic);
    }
}

@media screen and (min-width:375px) and (max-width: 425px) {
    .root {
        width: 360px ;
    }

    .trContainer {
        grid-template-columns:105px 2fr 1.5fr 1fr 1fr 1fr;
        font-size: 9px;
    }
}