.root {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 24px 0 24px  0;
}

.moneyBox {
    display: flex;
    align-items: center;
}

.balance {
    margin: 0 16px 0 16px;
    font-weight: 400;
    font-size: var(--fs-balance-money);
    color: var(--balance-money-color);
}

.updateBallance {
    margin-left: 16px;
}

.moneyButton {
    display: flex;
    justify-content: end;
    gap: 5px;
}

.balanceTablet {
    display: flex;
    align-items: center;
}

.balanceMobile {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.update {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.balanceContainer {
    font-size: var(--fs-big);
    font-weight: 600;
    color: var(--window-head-color);
}

.balanceMessage {
    width: fit-content;
    margin-bottom: 16px;
}

.balanceMessage2 {
    cursor: pointer;
    color: var(--primary-button-bg);
    margin-right: 8px;
}
.theline {
    border: 1px solid black;
    margin-bottom: 16px;
}

.lupa {
    width: 30px;
    height: 30px;
}

.message {
    display: flex;
    align-items: center;
}

.paymentText {
    margin-bottom: 16px;
    cursor: pointer;
    color: var(--primary-button-bg);
}

@media screen and (min-width: 320px) and (max-width: 550px) {
    .balanceMobile {
        display: flex;
        flex-direction: column;
    }

    .balance {
        font-size: 20px;
        margin: 0;
    }

    .update {
        margin-left: 10px;
    }
}
